var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./clojure.set.js");require("./clojure.walk.js");require("./metabase.mbql.util.js");require("./cljs.core.match.js");require("./shadow.js.shim.module$ttag.js");require("./clojure.string.js");require("./metabase.util.log.js");require("./metabase.util.malli.js");
'use strict';var UV,WV,YV,ZV,Xma,aW,bW,TV,cW,fW,Yma,Zma,$ma,ana,hW,iW,kW,lW,mW,nW,oW,pW,cna,dna,rW,ena,sW,fna,uW,gna,hna,ina,jna,kna,QV;$CLJS.RV=function(a,b){if("string"===typeof b)return QV(a,b);throw new TypeError("re-seq must match against a string.");};$CLJS.SV=function(a,b,c){var d=$CLJS.Hl(a,b);if($CLJS.n(d)){var e=$CLJS.R.j;d=$CLJS.Cb(d);c=c.h?c.h(d):c.call(null,d);a=e.call($CLJS.R,a,b,c)}return a};
UV=function(a){var b=TV;return $CLJS.Iz(function(c){return function(d,e,f){e=b.h?b.h(e):b.call(null,e);return c.j?c.j(d,e,f):c.call(null,d,e,f)}},a)};$CLJS.VV=function(a,b){return $CLJS.Iz(function(c){return function(d,e,f){return $CLJS.n(a.h?a.h(e):a.call(null,e))?c.j?c.j(d,e,f):c.call(null,d,e,f):d}},b)};WV=function(a){if($CLJS.jd(a))return a;throw $CLJS.Uh("Invalid pattern: don't know how to handle symbol.",new $CLJS.h(null,1,[$CLJS.si,a],null));};
$CLJS.XV=function(a,b,c){return $CLJS.td(c)?$CLJS.Wf.g(c,function(){return function f(e){return new $CLJS.ie(null,function(){for(;;){var k=$CLJS.y(e);if(k){if($CLJS.wd(k)){var l=$CLJS.ic(k),m=$CLJS.D(l),t=$CLJS.le(m);return function(){for(var A=0;;)if(A<m){var C=$CLJS.id(l,A),G=$CLJS.I(C,0,null),J=$CLJS.I(C,1,null);C=t;var S=$CLJS.Q,X=G;G=$CLJS.Yd.g(b,G);G=a.g?a.g(G,J):a.call(null,G,J);C.add(new $CLJS.P(null,2,5,S,[X,G],null));A+=1}else return!0}()?$CLJS.oe($CLJS.qe(t),f($CLJS.jc(k))):$CLJS.oe($CLJS.qe(t),
null)}var u=$CLJS.z(k),v=$CLJS.I(u,0,null),x=$CLJS.I(u,1,null);return $CLJS.ae(new $CLJS.P(null,2,5,$CLJS.Q,[v,function(){var A=$CLJS.Yd.g(b,v),C=x;return a.g?a.g(A,C):a.call(null,A,C)}()],null),f($CLJS.Hc(k)))}return null}},null,null)}(c)}()):$CLJS.sd(c)?$CLJS.pk.g($CLJS.Qe(a,$CLJS.z(c)instanceof $CLJS.M?$CLJS.Yd.g(b,$CLJS.z(c)):b),c):c};YV=function(a){var b=(b=a instanceof $CLJS.M)?$CLJS.de(a):b;return $CLJS.n(b)?[$CLJS.de(a),"/",$CLJS.Xg(a)].join(""):$CLJS.Xg(a)};
ZV=function(a,b){var c=$CLJS.sd(b)&&!$CLJS.uf(b)&&$CLJS.z(b)instanceof $CLJS.M;return c?$CLJS.qd(a)?(b=$CLJS.z(b),a=$CLJS.Wg(a),a.h?a.h(b):a.call(null,b)):$CLJS.E.g(a,$CLJS.z(b)):c};
Xma=function(a,b){return $CLJS.df(function(c){var d=$CLJS.ff;a:try{if($CLJS.vd(c)&&1<=$CLJS.D(c))try{var e=$CLJS.uk.j(c,0,1);if($CLJS.vd(e)&&1===$CLJS.D(e))try{var f=$CLJS.F(e,0);if($CLJS.n($CLJS.Qe($CLJS.E,a)(f))){var k=$CLJS.uk.g(c,1);var l=new $CLJS.P(null,1,5,$CLJS.Q,[k],null)}else throw $CLJS.Z;}catch(t){if(t instanceof Error){var m=t;if(m===$CLJS.Z)throw $CLJS.Z;throw m;}throw t;}else throw $CLJS.Z;}catch(t){if(t instanceof Error){m=t;if(m===$CLJS.Z)throw $CLJS.Z;throw m;}throw t;}else throw $CLJS.Z;
}catch(t){if(t instanceof Error){m=t;if(m===$CLJS.Z){l=new $CLJS.P(null,1,5,$CLJS.Q,[new $CLJS.P(null,1,5,$CLJS.Q,[c],null)],null);break a}throw m;}throw t;}return $CLJS.z($CLJS.y(d($CLJS.Sa,l)))},$CLJS.H([b]))};
aW=function(a,b){for(;;)switch(b=$CLJS.Hk.h($CLJS.ff($CLJS.Sa,b)),$CLJS.D(b)){case 0:return null;case 1:return a=$CLJS.z(b),$V.h?$V.h(a):$V.call(null,a);default:if($CLJS.n($CLJS.Me($CLJS.Qe(ZV,a),b))){var c=a;b=Xma(a,b);a=c}else{c=$CLJS.cf.g($V,b);if($CLJS.E.g(c,b))return $CLJS.Wf.g(new $CLJS.P(null,1,5,$CLJS.Q,[a],null),b);b=c}}};bW=function(a){var b=$CLJS.sd(a);return b?(b=!$CLJS.uf(a))?$CLJS.zz($CLJS.be,$CLJS.Wa)($CLJS.z(a)):b:b};
TV=function(a){return $CLJS.n($CLJS.zz($CLJS.be,$CLJS.Wa)(a))?$CLJS.mh.h($CLJS.Gz(YV(a).toLowerCase(),/_/,"-")):a};cW=function(a,b){var c=bW(b);return $CLJS.n(c)?(b=TV($CLJS.z(b)),$CLJS.qd(a)?(a=$CLJS.Wg(a),a.h?a.h(b):a.call(null,b)):$CLJS.E.g(a,b)):c};
fW=function(a){a=dW.g?dW.g(a,eW):dW.call(null,a,eW);var b=$CLJS.n($CLJS.tA.h(a))?$CLJS.qk.j(a,$CLJS.tA,$CLJS.mh):a;b=$CLJS.n($CLJS.nF.h(a))?$CLJS.qk.j(b,$CLJS.nF,$CLJS.mh):b;return $CLJS.n($CLJS.tQ.h(a))?$CLJS.qk.j(b,$CLJS.tQ,function(c){return $CLJS.n($CLJS.zD.h(c))?$CLJS.qk.j(c,$CLJS.zD,$CLJS.mh):c}):b};
Yma=function(a){return $CLJS.K.j(new $CLJS.h(null,3,[$CLJS.Oh,$CLJS.Pd,$CLJS.Vi,TV,$CLJS.QM,TV],null),a,function(b){var c=$CLJS.K,d=c.g;b=$CLJS.Ae([a,b]);b=dW.g?dW.g(b,eW):dW.call(null,b,eW);return d.call(c,b,a)})};
Zma=function(a){a=$CLJS.Wf.j($CLJS.N,$CLJS.cf.h(function(b){var c=$CLJS.I(b,0,null);b=$CLJS.I(b,1,null);c=TV(c);var d=Yma(c);return new $CLJS.P(null,2,5,$CLJS.Q,[c,d.h?d.h(b):d.call(null,b)],null)}),a);return $CLJS.E.g($CLJS.Vi.h(a),$CLJS.AE)&&$CLJS.Ra($CLJS.QM.h(a))?$CLJS.R.j(a,$CLJS.QM,$CLJS.zF):a};$ma=function(a){return $CLJS.Wf.j($CLJS.N,$CLJS.cf.h(function(b){var c=$CLJS.I(b,0,null);b=$CLJS.I(b,1,null);c=YV(c);return new $CLJS.P(null,2,5,$CLJS.Q,[c,$CLJS.R.j(Zma(b),$CLJS.T,c)],null)}),a)};
ana=function(a){a=dW.h?dW.h(a):dW.call(null,a);return gW.h?gW.h(a):gW.call(null,a)};hW=function(a){return $CLJS.SV($CLJS.SV($CLJS.cb(function(b,c){return $CLJS.SV(b,c,$CLJS.mh)},a,new $CLJS.P(null,6,5,$CLJS.Q,[$CLJS.bC,$CLJS.vga,$CLJS.DN,bna,$CLJS.Yy,$CLJS.di],null)),$CLJS.eB,ana),$CLJS.SM,$CLJS.uU)};iW=function(a){return $CLJS.Bd(a)?new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.hF,a,null],null):a};kW=function(a){return jW.h(iW(a))};
lW=function(a){var b=$CLJS.y(a);a=$CLJS.z(b);b=$CLJS.B(b);return $V($CLJS.Wf.g(new $CLJS.P(null,1,5,$CLJS.Q,[a],null),$CLJS.cf.g(jW,b)))};mW=function(a){var b=$CLJS.y(a);a=$CLJS.z(b);var c=$CLJS.B(b);b=$CLJS.z(c);c=$CLJS.B(c);return $CLJS.Wf.g(new $CLJS.P(null,2,5,$CLJS.Q,[a,kW(b)],null),$CLJS.cf.g(jW,c))};nW=function(a){var b=$CLJS.I(a,0,null);a=$CLJS.I(a,1,null);return $CLJS.n(a)?new $CLJS.P(null,2,5,$CLJS.Q,[b,kW(a)],null):new $CLJS.P(null,1,5,$CLJS.Q,[b],null)};
oW=function(a){var b=$CLJS.I(a,0,null);a=$CLJS.I(a,1,null);return new $CLJS.P(null,2,5,$CLJS.Q,[b,kW(a)],null)};pW=function(a){var b=$CLJS.I(a,0,null);a=$CLJS.I(a,1,null);return new $CLJS.P(null,2,5,$CLJS.Q,[b,jW.h(a)],null)};cna=function(a){return $CLJS.yf($CLJS.El(gW,qW(a)))};dna=function(a){for(;;)if($CLJS.n(bW(a)))a=new $CLJS.P(null,1,5,$CLJS.Q,[a],null);else return $CLJS.Be($CLJS.pk.g(iW,a))};
rW=function(a){a=$CLJS.O(a);var b=$CLJS.K.g(a,$CLJS.AE);return $CLJS.n(b)?$CLJS.qk.j(a,$CLJS.AE,jW):a};
ena=function(a){return $CLJS.Wf.g($CLJS.N,function(){return function d(c){return new $CLJS.ie(null,function(){for(;;){var e=$CLJS.y(c);if(e){if($CLJS.wd(e)){var f=$CLJS.ic(e),k=$CLJS.D(f),l=$CLJS.le(k);a:for(var m=0;;)if(m<k){var t=$CLJS.id(f,m),u=$CLJS.I(t,0,null);t=$CLJS.I(t,1,null);u=new $CLJS.P(null,2,5,$CLJS.Q,[u,rW(t)],null);l.add(u);m+=1}else{f=!0;break a}return f?$CLJS.oe($CLJS.qe(l),d($CLJS.jc(e))):$CLJS.oe($CLJS.qe(l),null)}f=$CLJS.z(e);l=$CLJS.I(f,0,null);f=$CLJS.I(f,1,null);return $CLJS.ae(new $CLJS.P(null,
2,5,$CLJS.Q,[l,rW(f)],null),d($CLJS.Hc(e)))}return null}},null,null)}(a)}())};sW=function(a){a=$CLJS.O(a);var b=$CLJS.K.g(a,$CLJS.FP);return $CLJS.n(b)?$CLJS.qk.j(a,$CLJS.FP,ena):a};fna=function(a){var b=$CLJS.O(a);a=$CLJS.K.g(b,$CLJS.iP);b=$CLJS.Ra(a)?tW.h?tW.h(b):tW.call(null,b):b;return $CLJS.n(a)?sW(b):b};uW=function(a){return $CLJS.qd(a)?$CLJS.y(a):null!=a};
gna=function(a){var b=$CLJS.O(a);a=$CLJS.K.g(b,$CLJS.DE);var c=$CLJS.K.g(b,$CLJS.uM),d=$CLJS.K.g(b,$CLJS.RN),e=$CLJS.K.g(b,$CLJS.iP);try{if($CLJS.n(d)){var f=$CLJS.O(b),k=$CLJS.K.g(f,$CLJS.RN);var l=$CLJS.WK($CLJS.gk.g(f,$CLJS.RN),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.DE,$CLJS.RN],null),k)}else l=b;var m=$CLJS.n(a)?$CLJS.qk.j(l,$CLJS.DE,tW):l,t=$CLJS.n(c)?$CLJS.qk.j(m,$CLJS.uM,$CLJS.Qe($CLJS.pk,gW)):m,u=$CLJS.n(e)?$CLJS.qk.j(t,$CLJS.iP,sW):t,v=$CLJS.O(u),x=$CLJS.K.g(v,$CLJS.iP);return $CLJS.n(x)?$CLJS.R.j(gW($CLJS.gk.g(v,
$CLJS.iP)),$CLJS.iP,x):gW(v)}catch(A){if(A instanceof Error)throw m=A,$CLJS.Th($CLJS.dD("Error canonicalizing query: {0}",$CLJS.H([$CLJS.sU(m)])),new $CLJS.h(null,1,[$CLJS.DE,a],null),m);throw A;}};
hna=function(a){a=$CLJS.O(a);var b=$CLJS.K.g(a,$CLJS.DE),c=$CLJS.O(b);b=$CLJS.K.g(c,$CLJS.WE);c=$CLJS.K.g(c,$CLJS.PD);return $CLJS.y(b)&&$CLJS.y(c)?(b=$CLJS.Wf.j($CLJS.Sg,$CLJS.qB,$CLJS.y($CLJS.ff($CLJS.Sa,function k(e,f){try{if($CLJS.vd(f)&&3===$CLJS.D(f))try{var l=$CLJS.F(f,0);if($CLJS.ce(l,$CLJS.hF)){var m=$CLJS.F(f,1),t=$CLJS.F(f,2);return new $CLJS.P(null,1,5,$CLJS.Q,[new $CLJS.P(null,2,5,$CLJS.Q,[f,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.hF,m,$CLJS.gk.g(t,$CLJS.nF)],null)],null)],null)}throw $CLJS.Z;
}catch(u){if(u instanceof Error){l=u;if(l===$CLJS.Z)throw $CLJS.Z;throw l;}throw u;}else throw $CLJS.Z;}catch(u){if(u instanceof Error){l=u;if(l===$CLJS.Z)return $CLJS.zU(k,e,f);throw l;}throw u;}}($CLJS.xf,b)))),$CLJS.vW.j(a,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.DE,$CLJS.PD],null),$CLJS.nk.g($CLJS.yf,$CLJS.Qe($CLJS.ok,b)))):a};
ina=function(a){try{return hna(a)}catch(c){if(c instanceof Error){var b=c;throw $CLJS.Th($CLJS.GD("Error performing whole query transformations"),new $CLJS.h(null,1,[$CLJS.DE,a],null),b);}throw c;}};
jna=function(a,b){var c=$CLJS.Wf.g($CLJS.gd(a),function(){return function f(e){return new $CLJS.ie(null,function(){for(var k=e;;)if(k=$CLJS.y(k)){if($CLJS.wd(k)){var l=$CLJS.ic(k),m=$CLJS.D(l),t=$CLJS.le(m);return function(){for(var A=0;;)if(A<m){var C=$CLJS.id(l,A),G=$CLJS.I(C,0,null);C=$CLJS.I(C,1,null);var J=$CLJS.Yd.g(b,G);C=wW.g?wW.g(C,J):wW.call(null,C,J);null!=C&&t.add(new $CLJS.P(null,2,5,$CLJS.Q,[G,C],null));A+=1}else return!0}()?$CLJS.oe($CLJS.qe(t),f($CLJS.jc(k))):$CLJS.oe($CLJS.qe(t),
null)}var u=$CLJS.z(k),v=$CLJS.I(u,0,null),x=$CLJS.I(u,1,null);u=function(){var A=x,C=$CLJS.Yd.g(b,v);return wW.g?wW.g(A,C):wW.call(null,A,C)}();if(null!=u)return $CLJS.ae(new $CLJS.P(null,2,5,$CLJS.Q,[v,u],null),f($CLJS.Hc(k)));k=$CLJS.Hc(k)}else return null},null,null)}(a)}());return $CLJS.y(c)?c:null};kna=function(a,b){a=$CLJS.pk.g(function(c){var d=$CLJS.Yd.g(b,xW);return wW.g?wW.g(c,d):wW.call(null,c,d)},a);return $CLJS.n($CLJS.Me($CLJS.Sa,a))?a:null};
QV=function QV(a,b){var d=a.exec(b);if(null==d)return null;var e=d[0],f=1===d.length?e:$CLJS.yf(d);return $CLJS.ae(f,new $CLJS.ie(null,function(){var k=e.length;k=d.index+(1>k?1:k);return k<=b.length?(k=b.substring(k),QV.g?QV.g(a,k):QV.call(null,a,k)):null},null,null))};
$CLJS.vW=function vW(a){switch(arguments.length){case 3:return vW.j(arguments[0],arguments[1],arguments[2]);case 4:return vW.v(arguments[0],arguments[1],arguments[2],arguments[3]);case 5:return vW.M(arguments[0],arguments[1],arguments[2],arguments[3],arguments[4]);case 6:return vW.W(arguments[0],arguments[1],arguments[2],arguments[3],arguments[4],arguments[5]);default:for(var c=[],d=arguments.length,e=0;;)if(e<d)c.push(arguments[e]),e+=1;else break;return vW.l(arguments[0],arguments[1],arguments[2],
arguments[3],arguments[4],arguments[5],new $CLJS.w(c.slice(6),0,null))}};$CLJS.vW.j=function(a,b,c){var d=$CLJS.y(b);b=$CLJS.z(d);if(d=$CLJS.B(d))a=$CLJS.R.j(a,b,$CLJS.vW.j($CLJS.K.g(a,b),d,c));else{d=$CLJS.R.j;var e=$CLJS.K.g(a,b);c=c.h?c.h(e):c.call(null,e);a=d.call($CLJS.R,a,b,c)}return a};
$CLJS.vW.v=function(a,b,c,d){var e=$CLJS.y(b);b=$CLJS.z(e);if(e=$CLJS.B(e))a=$CLJS.R.j(a,b,$CLJS.vW.v($CLJS.K.g(a,b),e,c,d));else{e=$CLJS.R.j;var f=$CLJS.K.g(a,b);c=c.g?c.g(f,d):c.call(null,f,d);a=e.call($CLJS.R,a,b,c)}return a};$CLJS.vW.M=function(a,b,c,d,e){var f=$CLJS.y(b);b=$CLJS.z(f);if(f=$CLJS.B(f))a=$CLJS.R.j(a,b,$CLJS.vW.M($CLJS.K.g(a,b),f,c,d,e));else{f=$CLJS.R.j;var k=$CLJS.K.g(a,b);c=c.j?c.j(k,d,e):c.call(null,k,d,e);a=f.call($CLJS.R,a,b,c)}return a};
$CLJS.vW.W=function(a,b,c,d,e,f){var k=$CLJS.y(b);b=$CLJS.z(k);if(k=$CLJS.B(k))a=$CLJS.R.j(a,b,$CLJS.vW.W($CLJS.K.g(a,b),k,c,d,e,f));else{k=$CLJS.R.j;var l=$CLJS.K.g(a,b);c=c.v?c.v(l,d,e,f):c.call(null,l,d,e,f);a=k.call($CLJS.R,a,b,c)}return a};$CLJS.vW.l=function(a,b,c,d,e,f,k){var l=$CLJS.y(b);b=$CLJS.z(l);return(l=$CLJS.B(l))?$CLJS.R.j(a,b,$CLJS.Ne.l($CLJS.vW,$CLJS.K.g(a,b),l,c,d,$CLJS.H([e,f,k]))):$CLJS.R.j(a,b,$CLJS.Ne.l(c,$CLJS.K.g(a,b),d,e,f,$CLJS.H([k])))};
$CLJS.vW.B=function(a){var b=$CLJS.z(a),c=$CLJS.B(a);a=$CLJS.z(c);var d=$CLJS.B(c);c=$CLJS.z(d);var e=$CLJS.B(d);d=$CLJS.z(e);var f=$CLJS.B(e);e=$CLJS.z(f);var k=$CLJS.B(f);f=$CLJS.z(k);k=$CLJS.B(k);return this.l(b,a,c,d,e,f,k)};$CLJS.vW.A=6;
var $V=function $V(a){for(;;){if($CLJS.td(a))return $CLJS.gs(a,$V);if($CLJS.zd(a))a=$CLJS.yf(a);else if($CLJS.vd(a)){if($CLJS.Ra($CLJS.Me($CLJS.Sa,a)))return null;var c=a,d=$CLJS.y(c),e=$CLJS.z(d),f=$CLJS.B(d),k=e,l=f,m=$CLJS.I(l,0,null),t=l,u=k,v=u instanceof $CLJS.M?u.S:null;switch(v){case "not":if($CLJS.nl(m)){var x=$CLJS.z(m),A=x instanceof $CLJS.M?x.S:null;switch(A){case "not":a=$CLJS.dd(m);continue;case "and":return aW($CLJS.gr,$CLJS.cf.g(function(){return function(C){return new $CLJS.P(null,
2,5,$CLJS.Q,[$CLJS.ir,C],null)}}(a,x,A,u,v,c,d,e,f,k,l,m,t),$CLJS.Hc(m)));case "or":return aW($CLJS.mr,$CLJS.cf.g(function(){return function(C){return new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ir,C],null)}}(a,x,A,u,v,c,d,e,f,k,l,m,t),$CLJS.Hc(m)));default:return a}}else return a;case "and":return aW($CLJS.mr,t);case "or":return aW($CLJS.gr,t);default:return $CLJS.pk.g($V,a)}}else return a}},lna=new $CLJS.M(null,"value_field","value_field",-980977878),yW=new $CLJS.M(null,"ascending","ascending",-988350486),
zW=new $CLJS.M(null,"named","named",-422393479),AW=new $CLJS.M(null,"values_source_config","values_source_config",-590570309),BW=new $CLJS.M(null,"descending","descending",-24766135),CW=new $CLJS.M(null,"datetime-field","datetime-field",21731696),mna=new $CLJS.M(null,"use-as-display-name?","use-as-display-name?",686752941),DW=new $CLJS.M(null,"field-literal","field-literal",-1295883554),EW=new $CLJS.M(null,"viz-settings","viz-settings",256055379),nna=new $CLJS.M(null,"rows","rows",850049680),ona=
new $CLJS.M(null,"special-fn","special-fn",1290649344),xW=new $CLJS.M("metabase.mbql.normalize","sequence","metabase.mbql.normalize/sequence",-1700645683),eW=new $CLJS.M(null,"ignore-path","ignore-path",944069061),pna=new $CLJS.M(null,"label_field","label_field",-1573182765),qna=new $CLJS.M(null,"fk-\x3e","fk-\x3e",-499026738),rna=new $CLJS.M(null,"joined-field","joined-field",-2048778268),bna=new $CLJS.M(null,"visibility_type","visibility_type",-508434247);var FW,sna=$CLJS.Se($CLJS.N),tna=$CLJS.Se($CLJS.N),una=$CLJS.Se($CLJS.N),vna=$CLJS.Se($CLJS.N),wna=$CLJS.K.j($CLJS.N,$CLJS.Si,$CLJS.Gh.o?$CLJS.Gh.o():$CLJS.Gh.call(null));FW=new $CLJS.Ph($CLJS.qh.g("metabase.mbql.normalize","normalize-mbql-clause-tokens"),$CLJS.nk.g(TV,$CLJS.z),wna,sna,tna,una,vna);FW.m(null,$CLJS.XE,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null);a=$CLJS.I(a,2,null);b=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.XE,b],null);return null!=a?$CLJS.Yd.g(b,a):b});
FW.m(null,$CLJS.qA,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null);a=$CLJS.I(a,2,null);b=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.qA,b instanceof $CLJS.M?YV(b):b],null);a=$CLJS.Be($CLJS.VV(new $CLJS.Rg(null,new $CLJS.h(null,3,[$CLJS.tA,null,$CLJS.nF,null,$CLJS.tQ,null],null),null),fW(a)));return $CLJS.n(a)?$CLJS.Yd.g(b,a):b});
FW.m(null,$CLJS.BU,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null),c=$CLJS.I(a,2,null);a=$CLJS.I(a,3,null);return $CLJS.n(a)?$CLJS.Yd.g(FW.h(new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.BU,b,c],null)),a):new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.BU,dW.g?dW.g(b,eW):dW.call(null,b,eW),TV(c)],null)});FW.m(null,$CLJS.hF,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null);a=$CLJS.I(a,2,null);return new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.hF,b,fW(a)],null)});
FW.m(null,DW,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null);a=$CLJS.I(a,2,null);return new $CLJS.P(null,3,5,$CLJS.Q,[DW,b instanceof $CLJS.M?YV(b):b,$CLJS.mh.h(a)],null)});FW.m(null,CW,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null),c=$CLJS.I(a,2,null);a=$CLJS.I(a,3,null);return $CLJS.n(a)?new $CLJS.P(null,4,5,$CLJS.Q,[CW,dW.g?dW.g(b,eW):dW.call(null,b,eW),$CLJS.Xr,TV(a)],null):new $CLJS.P(null,3,5,$CLJS.Q,[CW,dW.g?dW.g(b,eW):dW.call(null,b,eW),TV(c)],null)});
FW.m(null,$CLJS.cB,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null),c=$CLJS.I(a,2,null),d=$CLJS.I(a,3,null);a=$CLJS.I(a,4,null);return $CLJS.n(a)?$CLJS.Yd.g(FW.h(new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.cB,b,c,d],null)),dW.g?dW.g(a,eW):dW.call(null,a,eW)):new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.cB,dW.g?dW.g(b,eW):dW.call(null,b,eW),$CLJS.Bd(c)?c:TV(c),TV(d)],null)});
FW.m(null,$CLJS.ZA,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null);a=$CLJS.I(a,2,null);return $CLJS.n(a)?new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ZA,b,TV(a)],null):new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ZA,$CLJS.Qu],null)});FW.m(null,$CLJS.YA,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null);a=$CLJS.I(a,2,null);return new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.YA,b,TV(a)],null)});
FW.m(null,$CLJS.aB,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null),c=$CLJS.I(a,2,null);a=$CLJS.I(a,3,null);return new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.aB,dW.g?dW.g(b,eW):dW.call(null,b,eW),c,TV(a)],null)});FW.m(null,$CLJS.dB,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null),c=$CLJS.I(a,2,null);a=$CLJS.I(a,3,null);return new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.dB,dW.g?dW.g(b,eW):dW.call(null,b,eW),c,TV(a)],null)});
FW.m(null,$CLJS.$A,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null);a=$CLJS.I(a,2,null);return $CLJS.n(a)?new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.$A,dW.g?dW.g(b,eW):dW.call(null,b,eW),TV(a)],null):new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.$A,dW.g?dW.g(b,eW):dW.call(null,b,eW)],null)});
FW.m(null,$CLJS.bB,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null),c=$CLJS.I(a,2,null);a=$CLJS.I(a,3,null);return $CLJS.n(a)?new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.bB,dW.g?dW.g(b,eW):dW.call(null,b,eW),TV(c),TV(a)],null):new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.bB,dW.g?dW.g(b,eW):dW.call(null,b,eW),TV(c)],null)});
FW.m(null,$CLJS.XA,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null),c=$CLJS.I(a,2,null);a=$CLJS.I(a,3,null);return new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.XA,dW.g?dW.g(b,eW):dW.call(null,b,eW),dW.g?dW.g(c,eW):dW.call(null,c,eW),TV(a)],null)});FW.m(null,$CLJS.hj,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null);a=$CLJS.I(a,2,null);return new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.hj,b,a],null)});
FW.m(null,$CLJS.Oh,function(a){var b=$CLJS.y(a);a=$CLJS.z(b);b=$CLJS.B(b);return $CLJS.Wf.j(new $CLJS.P(null,1,5,$CLJS.Q,[TV(a)],null),$CLJS.cf.h(function(c){return dW.g?dW.g(c,eW):dW.call(null,c,eW)}),b)});
var GW=function GW(a){if($CLJS.n($CLJS.zz($CLJS.be,$CLJS.Wa)(a))){var c=TV(a);var d=new $CLJS.Rg(null,new $CLJS.h(null,18,[$CLJS.ui,null,$CLJS.Oq,null,$CLJS.vF,null,$CLJS.qF,null,$CLJS.Zr,null,$CLJS.iF,null,$CLJS.AF,null,$CLJS.BF,null,$CLJS.ME,null,$CLJS.TE,null,$CLJS.Nq,null,$CLJS.NE,null,$CLJS.uF,null,$CLJS.oF,null,$CLJS.Fj,null,$CLJS.Rw,null,$CLJS.bF,null,$CLJS.tF,null],null),null);c=d.h?d.h(c):d.call(null,c)}else c=null;return $CLJS.n(c)?c:$CLJS.n(bW(a))?(a=$CLJS.z(a),GW.h?GW.h(a):GW.call(null,
a)):null},xna=new $CLJS.h(null,8,[$CLJS.Vi,TV,$CLJS.iP,function(a){a=UV(a);return $CLJS.y($CLJS.FP.h(a))?$CLJS.qk.j(a,$CLJS.FP,$ma):a},$CLJS.DE,new $CLJS.h(null,6,[$CLJS.XE,function HW(a){if($CLJS.n($CLJS.zz($CLJS.be,$CLJS.Wa)(a)))return TV(a);if($CLJS.n(cW(zW,a))){a=$CLJS.y(a);$CLJS.z(a);var c=$CLJS.B(a);a=$CLJS.z(c);c=$CLJS.B(c);return $CLJS.Wf.g(new $CLJS.P(null,2,5,$CLJS.Q,[zW,HW.h?HW.h(a):HW.call(null,a)],null),c)}return $CLJS.n($CLJS.n(bW(a))?GW($CLJS.dd(a)):null)?$CLJS.pk.g(HW,a):dW.g?dW.g(a,
eW):dW.call(null,a,eW)},$CLJS.ZO,function(a){return $CLJS.Wf.g($CLJS.N,function(){return function d(c){return new $CLJS.ie(null,function(){for(;;){var e=$CLJS.y(c);if(e){if($CLJS.wd(e)){var f=$CLJS.ic(e),k=$CLJS.D(f),l=$CLJS.le(k);a:for(var m=0;;)if(m<k){var t=$CLJS.id(f,m),u=$CLJS.I(t,0,null);t=$CLJS.I(t,1,null);u=new $CLJS.P(null,2,5,$CLJS.Q,[YV(u),dW.g?dW.g(t,eW):dW.call(null,t,eW)],null);l.add(u);m+=1}else{f=!0;break a}return f?$CLJS.oe($CLJS.qe(l),d($CLJS.jc(e))):$CLJS.oe($CLJS.qe(l),null)}f=
$CLJS.z(e);l=$CLJS.I(f,0,null);f=$CLJS.I(f,1,null);return $CLJS.ae(new $CLJS.P(null,2,5,$CLJS.Q,[YV(l),dW.g?dW.g(f,eW):dW.call(null,f,eW)],null),d($CLJS.Hc(e)))}return null}},null,null)}(a)}())},$CLJS.NQ,function(a){return $CLJS.yf(function(){return function d(c){return new $CLJS.ie(null,function(){for(;;){var e=$CLJS.y(c);if(e){if($CLJS.wd(e)){var f=$CLJS.ic(e),k=$CLJS.D(f),l=$CLJS.le(k);a:for(var m=0;;)if(m<k){var t=$CLJS.id(f,m);t=$CLJS.n(bW(t))?FW.h(t):$CLJS.Zd(FW.h($CLJS.Zd(t)));l.add(t);m+=
1}else{f=!0;break a}return f?$CLJS.oe($CLJS.qe(l),d($CLJS.jc(e))):$CLJS.oe($CLJS.qe(l),null)}l=$CLJS.z(e);return $CLJS.ae($CLJS.n(bW(l))?FW.h(l):$CLJS.Zd(FW.h($CLJS.Zd(l))),d($CLJS.Hc(e)))}return null}},null,null)}(a)}())},$CLJS.HP,function(a){a=UV(a);a=$CLJS.O(a);var b=$CLJS.K.g(a,$CLJS.iP);if($CLJS.n(b))return a=$CLJS.yU(a,new $CLJS.h(null,1,[$CLJS.iP,$CLJS.DE],null)),b=new $CLJS.P(null,1,5,$CLJS.Q,[$CLJS.iP],null),a=dW.g?dW.g(a,b):dW.call(null,a,b),$CLJS.yU(a,new $CLJS.h(null,1,[$CLJS.DE,$CLJS.iP],
null));b=new $CLJS.P(null,1,5,$CLJS.Q,[$CLJS.DE],null);return dW.g?dW.g(a,b):dW.call(null,a,b)},$CLJS.RN,new $CLJS.h(null,1,[xW,hW],null),$CLJS.DM,new $CLJS.h(null,1,[xW,function(a){a=dW.g?dW.g(a,$CLJS.DE):dW.call(null,a,$CLJS.DE);var b=$CLJS.O(a),c=$CLJS.K.g(b,$CLJS.zD),d=$CLJS.K.g(b,$CLJS.PD);a=$CLJS.K.g(b,$CLJS.TD);b=$CLJS.n(c)?$CLJS.qk.j(b,$CLJS.zD,TV):b;d=$CLJS.n($CLJS.zz($CLJS.be,$CLJS.Wa)(d))?$CLJS.qk.j(b,$CLJS.PD,TV):b;return $CLJS.n(a)?$CLJS.qk.j(d,$CLJS.TD,YV):d}],null)],null),$CLJS.Gx,
new $CLJS.h(null,1,[$CLJS.oN,$CLJS.Pd],null),$CLJS.uM,new $CLJS.h(null,1,[xW,function(a){var b=$CLJS.O(a),c=$CLJS.K.g(b,$CLJS.Vi),d=$CLJS.K.g(b,$CLJS.mI),e=$CLJS.K.g(b,$CLJS.Ki);a=$CLJS.K.g(b,AW);b=$CLJS.n(e)?$CLJS.qk.j(b,$CLJS.Ki,YV):b;c=$CLJS.n(c)?$CLJS.qk.j(b,$CLJS.Vi,TV):b;d=$CLJS.n(d)?$CLJS.qk.j(c,$CLJS.mI,function(f){return dW.g?dW.g(f,eW):dW.call(null,f,eW)}):c;d=$CLJS.n(a)?$CLJS.vW.j(d,new $CLJS.P(null,2,5,$CLJS.Q,[AW,pna],null),function(f){return dW.g?dW.g(f,eW):dW.call(null,f,eW)}):d;return $CLJS.n(a)?
$CLJS.vW.j(d,new $CLJS.P(null,2,5,$CLJS.Q,[AW,lna],null),function(f){return dW.g?dW.g(f,eW):dW.call(null,f,eW)}):d}],null),$CLJS.pM,function(a){return null==a?null:TV(a)},$CLJS.RN,new $CLJS.h(null,1,[xW,hW],null),EW,TV],null),dW=function dW(a){for(var c=[],d=arguments.length,e=0;;)if(e<d)c.push(arguments[e]),e+=1;else break;return dW.l(arguments[0],1<c.length?new $CLJS.w(c.slice(1),0,null):null)};
dW.l=function(a,b){b=$CLJS.I(b,0,null);var c=b instanceof $CLJS.M?new $CLJS.P(null,1,5,$CLJS.Q,[b],null):$CLJS.yf(b);b=$CLJS.y(c)?$CLJS.Bz(xna,c):null;try{return $CLJS.jd(b)?b.h?b.h(a):b.call(null,a):$CLJS.ud(a)?a:$CLJS.td(a)?$CLJS.Wf.g($CLJS.N,function(){return function k(f){return new $CLJS.ie(null,function(){for(;;){var l=$CLJS.y(f);if(l){if($CLJS.wd(l)){var m=$CLJS.ic(l),t=$CLJS.D(m),u=$CLJS.le(t);a:for(var v=0;;)if(v<t){var x=$CLJS.id(m,v),A=$CLJS.I(x,0,null);x=$CLJS.I(x,1,null);A=TV(A);A=new $CLJS.P(null,
2,5,$CLJS.Q,[A,dW.l(x,$CLJS.H([$CLJS.Yd.g($CLJS.yf(c),A)]))],null);u.add(A);v+=1}else{m=!0;break a}return m?$CLJS.oe($CLJS.qe(u),k($CLJS.jc(l))):$CLJS.oe($CLJS.qe(u),null)}m=$CLJS.z(l);u=$CLJS.I(m,0,null);m=$CLJS.I(m,1,null);u=TV(u);return $CLJS.ae(new $CLJS.P(null,2,5,$CLJS.Q,[u,dW.l(m,$CLJS.H([$CLJS.Yd.g($CLJS.yf(c),u)]))],null),k($CLJS.Hc(l)))}return null}},null,null)}(a)}()):$CLJS.n(bW(a))?FW.h(a):$CLJS.sd(a)?$CLJS.pk.g(function(e){return dW.l(e,$CLJS.H([$CLJS.Yd.g($CLJS.yf(c),xW)]))},a):a}catch(e){if(e instanceof
Error){var d=e;throw $CLJS.Th($CLJS.dD("Error normalizing form: {0}",$CLJS.H([$CLJS.sU(d)])),new $CLJS.h(null,3,[$CLJS.Gi,a,$CLJS.Tk,c,ona,b],null),d);}throw e;}};dW.A=1;dW.B=function(a){var b=$CLJS.z(a);a=$CLJS.B(a);return this.l(b,a)};
var jW=function(){var a=$CLJS.Se($CLJS.N),b=$CLJS.Se($CLJS.N),c=$CLJS.Se($CLJS.N),d=$CLJS.Se($CLJS.N),e=$CLJS.K.j($CLJS.N,$CLJS.Si,$CLJS.Gh.o?$CLJS.Gh.o():$CLJS.Gh.call(null));return new $CLJS.Ph($CLJS.qh.g("metabase.mbql.normalize","canonicalize-mbql-clause"),function(f){return $CLJS.n(bW(f))?$CLJS.z(f):null},e,a,b,c,d)}();jW.m(null,$CLJS.Oh,function(a){return a});
jW.m(null,$CLJS.hF,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null);a=$CLJS.I(a,2,null);if($CLJS.n(cW($CLJS.hF,b))){$CLJS.I(b,0,null);var c=$CLJS.I(b,1,null);b=$CLJS.I(b,2,null);return jW.h(new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.hF,c,$CLJS.Be($CLJS.Gk.l($CLJS.H([b,a])))],null))}return new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.hF,b,$CLJS.Be(a)],null)});
jW.m(null,$CLJS.XE,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null);a=$CLJS.I(a,2,null);return $CLJS.pd(a)?new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.XE,b],null):new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.XE,b,a],null)});jW.m(null,$CLJS.UJ,function(a){$CLJS.I(a,0,null);a=$CLJS.I(a,1,null);return $CLJS.n(bW(a))?jW.h(a):new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.hF,a,null],null)});
jW.m(null,DW,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null);a=$CLJS.I(a,2,null);return new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.hF,b,new $CLJS.h(null,1,[$CLJS.tA,a],null)],null)});jW.m(null,qna,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null);a=$CLJS.I(a,2,null);var c=kW(b);$CLJS.I(c,0,null);b=$CLJS.I(c,1,null);$CLJS.I(c,2,null);c=kW(a);$CLJS.I(c,0,null);a=$CLJS.I(c,1,null);c=$CLJS.I(c,2,null);return new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.hF,a,$CLJS.R.j(c,$CLJS.EM,b)],null)});
jW.m(null,rna,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null);a=$CLJS.I(a,2,null);a=kW(a);return $CLJS.Ne.v($CLJS.GU,a,$CLJS.R,$CLJS.H([$CLJS.tO,b]))});
jW.m(null,CW,function(a){var b=$CLJS.D(a);switch(b){case 3:$CLJS.I(a,0,null);b=$CLJS.I(a,1,null);var c=$CLJS.I(a,2,null);a=kW(b);b=c;$CLJS.I(a,0,null);$CLJS.I(a,1,null);c=$CLJS.I(a,2,null);c=$CLJS.O(c);var d=$CLJS.K.g(c,$CLJS.tA);$CLJS.Ra(d)||$CLJS.JR.g(d,b)?a=$CLJS.Ne.v($CLJS.GU,a,$CLJS.R,$CLJS.H([$CLJS.nF,b])):(c=$CLJS.Mz($CLJS.Ex),$CLJS.n($CLJS.Lz("metabase.mbql.util",c))&&(b=$CLJS.dD("{0} is not a valid temporal unit for {1}; not adding to clause {2}",$CLJS.H([b,d,$CLJS.xh.l($CLJS.H([a]))])),
b instanceof Error?$CLJS.Kz("metabase.mbql.util",c,$CLJS.xv(),b):$CLJS.Kz("metabase.mbql.util",c,$CLJS.xv.l($CLJS.H([b])),null)));return a;case 4:return $CLJS.I(a,0,null),b=$CLJS.I(a,1,null),$CLJS.I(a,2,null),c=$CLJS.I(a,3,null),jW.h(new $CLJS.P(null,3,5,$CLJS.Q,[CW,b,c],null));default:throw Error(["No matching clause: ",$CLJS.p.h(b)].join(""));}});
jW.m(null,$CLJS.BU,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null),c=$CLJS.I(a,2,null),d=$CLJS.I(a,3,null);a=$CLJS.I(a,4,null);var e=kW(b);$CLJS.I(e,0,null);b=$CLJS.I(e,1,null);e=$CLJS.I(e,2,null);return new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.hF,b,$CLJS.R.j(e,$CLJS.tQ,$CLJS.Gk.l($CLJS.H([new $CLJS.h(null,1,[$CLJS.zD,c],null),$CLJS.n(d)?$CLJS.Ae([c,d]):null,a])))],null)});
for(var IW=$CLJS.y(new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.mr,$CLJS.gr,$CLJS.ir],null)),JW=null,KW=0,LW=0;;)if(LW<KW){var MW=JW.X(null,LW);jW.m(null,MW,function(){return function(a){return lW(a)}}(IW,JW,KW,LW,MW));LW+=1}else{var NW=$CLJS.y(IW);if(NW){var OW=NW;if($CLJS.wd(OW)){var PW=$CLJS.ic(OW),yna=$CLJS.jc(OW),zna=PW,Ana=$CLJS.D(PW);IW=yna;JW=zna;KW=Ana}else{var QW=$CLJS.z(OW);jW.m(null,QW,function(){return function(a){return lW(a)}}(IW,JW,KW,LW,QW,OW,NW));IW=$CLJS.B(OW);JW=null;KW=0}LW=0}else break}
jW.m(null,$CLJS.PE,function(a){a=$CLJS.y(a);$CLJS.z(a);var b=$CLJS.B(a);a=$CLJS.z(b);var c=$CLJS.B(b);b=$CLJS.z(c);c=$CLJS.B(c);return $CLJS.Wf.g(new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.PE,kW(a),kW(b)],null),c)});jW.m(null,$CLJS.cB,function(a){a=$CLJS.y(a);$CLJS.z(a);var b=$CLJS.B(a);a=$CLJS.z(b);b=$CLJS.B(b);var c=kW(a);a=$CLJS.n(ZV($CLJS.hF,a))?$CLJS.GU.l(c,$CLJS.gk,$CLJS.H([$CLJS.nF])):c;return $CLJS.Wf.g(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.cB,a],null),b)});
for(var RW=$CLJS.y(new $CLJS.P(null,15,5,$CLJS.Q,[$CLJS.ZE,$CLJS.sF,$CLJS.gF,$CLJS.$E,$CLJS.Lq,$CLJS.xF,$CLJS.Iq,$CLJS.Kq,$CLJS.Eq,$CLJS.Gq,$CLJS.KE,$CLJS.RE,$CLJS.IE,$CLJS.UE,$CLJS.JE],null)),SW=null,TW=0,UW=0;;)if(UW<TW){var VW=SW.X(null,UW);jW.m(null,VW,function(){return function(a){return mW(a)}}(RW,SW,TW,UW,VW));UW+=1}else{var WW=$CLJS.y(RW);if(WW){var XW=WW;if($CLJS.wd(XW)){var YW=$CLJS.ic(XW),Bna=$CLJS.jc(XW),Cna=YW,Dna=$CLJS.D(YW);RW=Bna;SW=Cna;TW=Dna}else{var ZW=$CLJS.z(XW);jW.m(null,ZW,
function(){return function(a){return mW(a)}}(RW,SW,TW,UW,ZW,XW,WW));RW=$CLJS.B(XW);SW=null;TW=0}UW=0}else break}jW.m(null,nna,function(){return null});jW.m(null,$CLJS.uL,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null);a=$CLJS.I(a,2,null);return new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.uL,jW.h(b),a],null)});
jW.m(null,zW,function(a){a=$CLJS.y(a);$CLJS.z(a);a=$CLJS.B(a);var b=$CLJS.z(a),c=$CLJS.B(a);a=$CLJS.z(c);var d=$CLJS.B(c);c=jW.h;var e=$CLJS.Q;b=jW.h(b);d=$CLJS.I(d,0,null);d=$CLJS.O(d);a=!1===$CLJS.K.g(d,mna)?new $CLJS.h(null,1,[$CLJS.T,a],null):new $CLJS.h(null,1,[$CLJS.iD,a],null);return c.call(jW,new $CLJS.P(null,3,5,e,[$CLJS.uL,b,a],null))});
for(var $W=$CLJS.y(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Rw,$CLJS.iF],null)),aX=null,bX=0,cX=0;;)if(cX<bX){var dX=aX.X(null,cX);jW.m(null,dX,function(){return function(a){return nW(a)}}($W,aX,bX,cX,dX));cX+=1}else{var eX=$CLJS.y($W);if(eX){var fX=eX;if($CLJS.wd(fX)){var gX=$CLJS.ic(fX),Ena=$CLJS.jc(fX),Fna=gX,Gna=$CLJS.D(gX);$W=Ena;aX=Fna;bX=Gna}else{var hX=$CLJS.z(fX);jW.m(null,hX,function(){return function(a){return nW(a)}}($W,aX,bX,cX,hX,fX,eX));$W=$CLJS.B(fX);aX=null;bX=0}cX=0}else break}
for(var iX=$CLJS.y(new $CLJS.P(null,9,5,$CLJS.Q,[$CLJS.bF,$CLJS.CF,$CLJS.ME,$CLJS.vF,$CLJS.tF,$CLJS.ui,$CLJS.Fj,$CLJS.uF,$CLJS.NE],null)),jX=null,kX=0,lX=0;;)if(lX<kX){var mX=jX.X(null,lX);jW.m(null,mX,function(){return function(a){return oW(a)}}(iX,jX,kX,lX,mX));lX+=1}else{var nX=$CLJS.y(iX);if(nX){var oX=nX;if($CLJS.wd(oX)){var pX=$CLJS.ic(oX),Hna=$CLJS.jc(oX),Ina=pX,Jna=$CLJS.D(pX);iX=Hna;jX=Ina;kX=Jna}else{var qX=$CLJS.z(oX);jW.m(null,qX,function(){return function(a){return oW(a)}}(iX,jX,kX,lX,
qX,oX,nX));iX=$CLJS.B(oX);jX=null;kX=0}lX=0}else break}jW.m(null,$CLJS.TE,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null);a=$CLJS.I(a,2,null);return new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.TE,kW(b),a],null)});
for(var rX=$CLJS.y(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.oF,$CLJS.qF],null)),sX=null,tX=0,uX=0;;)if(uX<tX){var vX=sX.X(null,uX);jW.m(null,vX,function(){return function(a){return pW(a)}}(rX,sX,tX,uX,vX));uX+=1}else{var wX=$CLJS.y(rX);if(wX){var xX=wX;if($CLJS.wd(xX)){var yX=$CLJS.ic(xX),Kna=$CLJS.jc(xX),Lna=yX,Mna=$CLJS.D(yX);rX=Kna;sX=Lna;tX=Mna}else{var zX=$CLJS.z(xX);jW.m(null,zX,function(){return function(a){return pW(a)}}(rX,sX,tX,uX,zX,xX,wX));rX=$CLJS.B(xX);sX=null;tX=0}uX=0}else break}
jW.m(null,$CLJS.AF,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null);a=$CLJS.I(a,2,null);return new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.AF,jW.h(b),jW.h(a)],null)});
jW.m(null,$CLJS.QE,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null);a=$CLJS.I(a,2,null);return $CLJS.n(a)?$CLJS.Yd.g(jW.h(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.QE,b],null)),dW.l(a,$CLJS.H([eW]))):new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.QE,$CLJS.yf(function(){return function e(d){return new $CLJS.ie(null,function(){for(;;){var f=$CLJS.y(d);if(f){if($CLJS.wd(f)){var k=$CLJS.ic(f),l=$CLJS.D(k),m=$CLJS.le(l);a:for(var t=0;;)if(t<l){var u=$CLJS.id(k,t),v=$CLJS.I(u,0,null);u=$CLJS.I(u,1,null);v=new $CLJS.P(null,
2,5,$CLJS.Q,[jW.h(v),jW.h(u)],null);m.add(v);t+=1}else{k=!0;break a}return k?$CLJS.oe($CLJS.qe(m),e($CLJS.jc(f))):$CLJS.oe($CLJS.qe(m),null)}k=$CLJS.z(f);m=$CLJS.I(k,0,null);k=$CLJS.I(k,1,null);return $CLJS.ae(new $CLJS.P(null,2,5,$CLJS.Q,[jW.h(m),jW.h(k)],null),e($CLJS.Hc(f)))}return null}},null,null)}(b)}())],null)});
jW.m(null,$CLJS.zE,function(a){a=$CLJS.y(a);$CLJS.z(a);var b=$CLJS.B(a);a=$CLJS.z(b);var c=$CLJS.B(b);b=$CLJS.z(c);c=$CLJS.B(c);return $CLJS.Wf.g(new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.zE,jW.h(a),$CLJS.E.g(0,b)?1:jW.h(b)],null),$CLJS.cf.g(jW,c))});
var gW=function gW(a){if($CLJS.ud(a))return $CLJS.Od(function(d,e,f){return $CLJS.R.j(d,e,gW.h?gW.h(f):gW.call(null,f))},a,a);if($CLJS.td(a))return $CLJS.gs(a,gW);if($CLJS.n(bW(a))){var c=function(){try{return jW.h(a)}catch(k){if(k instanceof Error){var d=k,e=$CLJS.Mz($CLJS.Cx);if($CLJS.n($CLJS.Lz("metabase.mbql.normalize",e))){var f=$CLJS.GD("Invalid clause:");f instanceof Error?$CLJS.Kz("metabase.mbql.normalize",e,$CLJS.xv.l($CLJS.H([a])),f):$CLJS.Kz("metabase.mbql.normalize",e,$CLJS.xv.l($CLJS.H([f,
a])),null)}throw $CLJS.Th($CLJS.dD("Invalid MBQL clause: {0}",$CLJS.H([$CLJS.sU(d)])),new $CLJS.h(null,1,[$CLJS.YQ,a],null),d);}throw k;}}();return $CLJS.y(c)?$CLJS.Wf.j($CLJS.Yd.g($CLJS.gd(c),$CLJS.z(c)),$CLJS.cf.h(gW),$CLJS.Hc(c)):c}return $CLJS.zd(a)?$CLJS.pk.g(gW,a):$CLJS.qd(a)?$CLJS.Wf.j($CLJS.gd(a),$CLJS.cf.h(gW),a):a},qW=function qW(a){return function f(d,e){try{if($CLJS.n(function(){var x=WV($CLJS.zd);return x.h?x.h(e):x.call(null,e)}()))return f(d,$CLJS.yf(e));throw $CLJS.Z;}catch(x){if(x instanceof
Error)if(d=x,d===$CLJS.Z)try{if($CLJS.n(function(){var A=WV($CLJS.be);return A.h?A.h(e):A.call(null,e)}()))return new $CLJS.P(null,1,5,$CLJS.Q,[new $CLJS.P(null,1,5,$CLJS.Q,[e],null)],null);throw $CLJS.Z;}catch(A){if(A instanceof Error)if(d=A,d===$CLJS.Z)try{if($CLJS.vd(e)&&2<=$CLJS.D(e))try{var k=$CLJS.uk.j(e,0,2);if($CLJS.vd(k)&&2===$CLJS.D(k))try{var l=$CLJS.F(k,0);if($CLJS.n($CLJS.bD($CLJS.be,$CLJS.Oe(new $CLJS.Rg(null,new $CLJS.h(null,5,[$CLJS.Oq,null,$CLJS.Zr,null,$CLJS.BF,null,$CLJS.Nq,null,
zW,null],null),null)))(l)))try{var m=$CLJS.F(k,1);if($CLJS.n(GW(m)))return $CLJS.Wf.j($CLJS.xf,$CLJS.cD(qW),a);throw $CLJS.Z;}catch(C){if(C instanceof Error){var t=C;if(t===$CLJS.Z)throw $CLJS.Z;throw t;}throw C;}else throw $CLJS.Z;}catch(C){if(C instanceof Error){t=C;if(t===$CLJS.Z)throw $CLJS.Z;throw t;}throw C;}else throw $CLJS.Z;}catch(C){if(C instanceof Error){t=C;if(t===$CLJS.Z)throw $CLJS.Z;throw t;}throw C;}else throw $CLJS.Z;}catch(C){if(C instanceof Error)if(t=C,t===$CLJS.Z)try{if($CLJS.vd(e)&&
1<=$CLJS.D(e))try{var u=$CLJS.uk.j(e,0,1);if($CLJS.vd(u)&&1===$CLJS.D(u))try{if($CLJS.F(u,0)instanceof $CLJS.M)return new $CLJS.P(null,1,5,$CLJS.Q,[e],null);throw $CLJS.Z;}catch(G){if(G instanceof Error){var v=G;if(v===$CLJS.Z)throw $CLJS.Z;throw v;}throw G;}else throw $CLJS.Z;}catch(G){if(G instanceof Error){v=G;if(v===$CLJS.Z)throw $CLJS.Z;throw v;}throw G;}else throw $CLJS.Z;}catch(G){if(G instanceof Error){v=G;if(v===$CLJS.Z)return e;throw v;}throw G;}else throw t;else throw C;}else throw d;else throw A;
}else throw d;else throw x;}}($CLJS.xf,a)},AX=function AX(a){return function f(d,e){try{var k=WV($CLJS.zd);var l=k.h?k.h(e):k.call(null,e);if($CLJS.n(l))return f(d,$CLJS.yf(e));throw $CLJS.Z;}catch(C){if(C instanceof Error)if(l=C,l===$CLJS.Z)try{if($CLJS.vd(e)&&2===$CLJS.D(e))try{var m=$CLJS.F(e,1);if($CLJS.ce(m,$CLJS.eF)){var t=$CLJS.F(e,0);return f(d,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.eF,t],null))}throw $CLJS.Z;}catch(G){if(G instanceof Error)if(l=G,l===$CLJS.Z)try{m=$CLJS.F(e,1);if($CLJS.ce(m,
$CLJS.YE))return t=$CLJS.F(e,0),f(d,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.YE,t],null));throw $CLJS.Z;}catch(J){if(J instanceof Error){var u=J;if(u===$CLJS.Z)try{m=$CLJS.F(e,1);if($CLJS.ce(m,yW))return t=$CLJS.F(e,0),f(d,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.eF,t],null));throw $CLJS.Z;}catch(S){if(S instanceof Error)if(l=S,l===$CLJS.Z)try{m=$CLJS.F(e,1);if($CLJS.ce(m,BW))return t=$CLJS.F(e,0),f(d,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.YE,t],null));throw $CLJS.Z;}catch(X){if(X instanceof Error)if(m=X,m===
$CLJS.Z)try{var v=$CLJS.F(e,0);if($CLJS.ce(v,yW))return t=$CLJS.F(e,1),f(d,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.eF,t],null));throw $CLJS.Z;}catch(W){if(W instanceof Error)if(m=W,m===$CLJS.Z)try{v=$CLJS.F(e,0);if($CLJS.ce(v,BW))return t=$CLJS.F(e,1),f(d,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.YE,t],null));throw $CLJS.Z;}catch(da){if(da instanceof Error)if(m=da,m===$CLJS.Z)try{v=$CLJS.F(e,0);if($CLJS.ce(v,$CLJS.eF))return t=$CLJS.F(e,1),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.eF,kW(t)],null);throw $CLJS.Z;}catch(ra){if(ra instanceof
Error)if(m=ra,m===$CLJS.Z)try{v=$CLJS.F(e,0);if($CLJS.ce(v,$CLJS.YE))return t=$CLJS.F(e,1),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.YE,kW(t)],null);throw $CLJS.Z;}catch(Ma){if(Ma instanceof Error){t=Ma;if(t===$CLJS.Z)throw $CLJS.Z;throw t;}throw Ma;}else throw m;else throw ra;}else throw m;else throw da;}else throw m;else throw W;}else throw m;else throw X;}else throw l;else throw S;}else throw u;}else throw J;}else throw l;else throw G;}else throw $CLJS.Z;}catch(G){if(G instanceof Error)if(l=G,l===$CLJS.Z)try{if($CLJS.vd(e)&&
0<=$CLJS.D(e))try{var x=$CLJS.uk.j(e,0,0);if($CLJS.vd(x)&&0===$CLJS.D(x))try{var A=$CLJS.uk.g(e,0);if($CLJS.E.g(A,a))return $CLJS.yf($CLJS.Hk.h($CLJS.cf.g(AX,a)));throw $CLJS.Z;}catch(J){if(J instanceof Error){u=J;if(u===$CLJS.Z)throw $CLJS.Z;throw u;}throw J;}else throw $CLJS.Z;}catch(J){if(J instanceof Error){u=J;if(u===$CLJS.Z)throw $CLJS.Z;throw u;}throw J;}else throw $CLJS.Z;}catch(J){if(J instanceof Error){u=J;if(u===$CLJS.Z)return $CLJS.XV(f,d,e);throw u;}throw J;}else throw l;else throw G;
}else throw l;else throw C;}}($CLJS.xf,a)},tW=$CLJS.nk.g(gW,function(a){var b=uW($CLJS.XE.h(a))?$CLJS.qk.j(a,$CLJS.XE,cna):a;b=uW($CLJS.WE.h(a))?$CLJS.qk.j(b,$CLJS.WE,dna):b;b=uW($CLJS.PD.h(a))?$CLJS.qk.j(b,$CLJS.PD,$CLJS.Qe($CLJS.pk,iW)):b;b=uW($CLJS.NQ.h(a))?$CLJS.qk.j(b,$CLJS.NQ,AX):b;return uW($CLJS.HP.h(a))?$CLJS.qk.j(b,$CLJS.HP,fna):b}),Nna=new $CLJS.h(null,3,[$CLJS.iP,$CLJS.Pd,$CLJS.DE,new $CLJS.h(null,2,[$CLJS.HP,function(a){a=$CLJS.O(a);var b=$CLJS.K.g(a,$CLJS.iP);if($CLJS.n(b))return a=
$CLJS.yU(a,new $CLJS.h(null,1,[$CLJS.iP,$CLJS.DE],null)),b=new $CLJS.P(null,1,5,$CLJS.Q,[$CLJS.iP],null),a=wW.g?wW.g(a,b):wW.call(null,a,b),$CLJS.yU(a,new $CLJS.h(null,1,[$CLJS.DE,$CLJS.iP],null));b=new $CLJS.P(null,1,5,$CLJS.Q,[$CLJS.DE],null);return wW.g?wW.g(a,b):wW.call(null,a,b)},$CLJS.DM,new $CLJS.h(null,1,[xW,function(a){var b=new $CLJS.P(null,1,5,$CLJS.Q,[$CLJS.DE],null);return wW.g?wW.g(a,b):wW.call(null,a,b)}],null)],null),EW,$CLJS.Pd],null),wW=function wW(a){switch(arguments.length){case 1:return wW.h(arguments[0]);
case 2:return wW.g(arguments[0],arguments[1]);default:throw Error(["Invalid arity: ",$CLJS.p.h(arguments.length)].join(""));}};wW.h=function(a){return wW.g(a,$CLJS.xf)};wW.g=function(a,b){try{var c=$CLJS.y(b)?$CLJS.Bz(Nna,b):null;return $CLJS.jd(c)?c.h?c.h(a):c.call(null,a):$CLJS.ud(a)?a:$CLJS.td(a)?jna(a,b):$CLJS.sd(a)?kna(a,b):a}catch(d){if(d instanceof Error)throw $CLJS.Th("Error removing empty clauses from form.",new $CLJS.h(null,2,[$CLJS.Gi,a,$CLJS.Tk,b],null),d);throw d;}};wW.A=2;
$CLJS.BX=function(){var a=$CLJS.nk.l(wW,ina,gna,$CLJS.H([dW]));return function(b){try{return a(b)}catch(d){if(d instanceof Error){var c=d;throw $CLJS.Th($CLJS.dD("Error normalizing query: {0}",$CLJS.H([$CLJS.sU(c)])),new $CLJS.h(null,1,[$CLJS.DE,b],null),c);}throw d;}}}();$CLJS.CX=function CX(a,b){if($CLJS.Ra($CLJS.y(a)))a=(0,$CLJS.BX)(b);else{var d=$CLJS.K,e=d.g;var f=$CLJS.es(a);b=$CLJS.Ae([$CLJS.fd(a),b]);f=CX.g?CX.g(f,b):CX.call(null,f,b);a=e.call(d,f,$CLJS.fd(a))}return a};