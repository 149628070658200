var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./clojure.string.js");require("./metabase.lib.dispatch.js");require("./metabase.lib.hierarchy.js");require("./metabase.lib.schema.common.js");require("./metabase.lib.schema.expression.js");require("./metabase.lib.schema.id.js");require("./metabase.lib.schema.mbql_clause.js");require("./metabase.lib.schema.temporal_bucketing.js");require("./metabase.types.js");require("./metabase.util.malli.registry.js");
'use strict';var BK,DK,FK,IK,LK;$CLJS.zK=function(a){return $CLJS.Hh($CLJS.q($CLJS.fB),a,$CLJS.lF)};$CLJS.AK=new $CLJS.M("lib","source-name","lib/source-name",1931622490);BK=new $CLJS.M("metabase.lib.schema.ref","aggregation-options","metabase.lib.schema.ref/aggregation-options",-15566487);$CLJS.CK=new $CLJS.M(null,"operators","operators",-2064102509);DK=new $CLJS.M("metabase.lib.schema.ref","field.options","metabase.lib.schema.ref/field.options",408316031);
$CLJS.EK=new $CLJS.M(null,"stage-number","stage-number",-1752729638);FK=new $CLJS.M("metabase.lib.schema.ref","field.literal","metabase.lib.schema.ref/field.literal",-1228060748);$CLJS.GK=new $CLJS.M(null,"min-value","min-value",-1119123315);$CLJS.HK=new $CLJS.M(null,"dimensions","dimensions",-254818097);IK=new $CLJS.M("metabase.lib.schema.ref","field.id","metabase.lib.schema.ref/field.id",-956546909);$CLJS.JK=new $CLJS.M(null,"max-value","max-value",687805168);
$CLJS.KK=new $CLJS.M("metabase.lib.schema","query","metabase.lib.schema/query",-1207387140);LK=new $CLJS.M("metabase.lib.schema.ref","field.literal.options","metabase.lib.schema.ref/field.literal.options",-860446668);$CLJS.MK=new $CLJS.M(null,"null","null",-180137709);$CLJS.NK=new $CLJS.M("metabase.lib.drill-thru","drill-thru","metabase.lib.drill-thru/drill-thru",-67424984);$CLJS.Y(DK,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ss,$CLJS.tD,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Ej,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.nF,new $CLJS.h(null,1,[$CLJS.yr,!0],null),$CLJS.$F],null)],null)],null));$CLJS.Y(LK,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ss,DK,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Ej,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.tA,$CLJS.xD],null)],null)],null));$CLJS.Y(FK,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.ji,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Lq,$CLJS.hF],null),LK,$CLJS.nD],null));
$CLJS.Y(IK,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.ji,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Lq,$CLJS.hF],null),DK,$CLJS.hK],null));
$CLJS.GF.g($CLJS.hF,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.mr,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.ji,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Lq,$CLJS.hF],null),DK,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.gr,$CLJS.hK,$CLJS.nD],null)],null),new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.lr,new $CLJS.h(null,2,[$CLJS.zi,function(a){return $CLJS.sd(a)?($CLJS.I(a,0,null),$CLJS.I(a,1,null),a=$CLJS.I(a,2,null),$CLJS.WA(a)):null},$CLJS.ts,"Invalid :field clause ID or name: must be a string or integer"],null),new $CLJS.P(null,2,
5,$CLJS.Q,[$CLJS.lA,IK],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.kA,FK],null)],null)],null));$CLJS.nE($CLJS.hF,$CLJS.lF);$CLJS.ID.m(null,$CLJS.hF,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null);$CLJS.I(a,2,null);a=$CLJS.zz($CLJS.qi,$CLJS.tA)(b);return $CLJS.n(a)?a:$CLJS.WD});$CLJS.uE($CLJS.qA,$CLJS.H([$CLJS.nD]));$CLJS.ID.m(null,$CLJS.qA,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null);$CLJS.I(a,2,null);a=$CLJS.zz($CLJS.qi,$CLJS.tA)(b);return $CLJS.n(a)?a:$CLJS.WD});
$CLJS.nE($CLJS.qA,$CLJS.lF);$CLJS.Y(BK,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ss,$CLJS.tD,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.Ej,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.T,new $CLJS.h(null,1,[$CLJS.yr,!0],null),$CLJS.nD],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.iD,new $CLJS.h(null,1,[$CLJS.yr,!0],null),$CLJS.nD],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.AK,new $CLJS.h(null,1,[$CLJS.yr,!0],null),$CLJS.nD],null)],null)],null));
$CLJS.GF.g($CLJS.XE,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.ji,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Lq,$CLJS.XE],null),BK,$CLJS.zj],null));$CLJS.ID.m(null,$CLJS.XE,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null);$CLJS.I(a,2,null);a=$CLJS.zz($CLJS.qi,$CLJS.tA)(b);return $CLJS.n(a)?a:$CLJS.WD});$CLJS.nE($CLJS.XE,$CLJS.lF);$CLJS.uE($CLJS.FE,$CLJS.H([$CLJS.Zr,$CLJS.qj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Jj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,$CLJS.MJ],null)],null)]));$CLJS.nE($CLJS.FE,$CLJS.lF);
$CLJS.uE($CLJS.cF,$CLJS.H([$CLJS.Zr,$CLJS.WD,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Jj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,$CLJS.KJ],null)],null)]));$CLJS.nE($CLJS.cF,$CLJS.lF);
$CLJS.Y($CLJS.lF,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.mr,$CLJS.KD,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Ci,new $CLJS.h(null,1,[$CLJS.Es,function(){return["Valid reference, must be one of these clauses: ",$CLJS.hs(", ",$CLJS.Cz($CLJS.Ed,$CLJS.Jk.g($CLJS.q($CLJS.fB),$CLJS.lF)))].join("")}],null),function(a){a=$CLJS.I(a,0,null);return $CLJS.zK(a)}],null)],null));